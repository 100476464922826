@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./assets/fonts/Poppins-Regular.ttf) format("ttf");
}

.tr-text-orange-700 {
  --tw-text-opacity: 1;
  color: #f29100;
}

.tr-text-orange-400 {
  --tw-text-opacity: 1;
  color: #fff6ea;
}
